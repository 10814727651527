import ToggleRowStyle from './Common';
import { useEffect, useState } from 'react';
import { supabaseClient } from './supabase';
import {
  Datagrid,
  EditButton,
  DeleteButton,
  List,
  Pagination,
  TextField,
  ReferenceField,
  Filter,
  SearchInput,
  useGetIdentity,
  useRecordContext,
  useGetList,
  SavedQueriesList,
  FilterLiveSearch,
  FilterList,
  Loading,
  FilterListItem,
  SelectArrayInput,
  SelectInput,
  DateField,
  useListContext,
  RichTextField,
  required,
} from 'react-admin';
import { Button, Card, CardContent } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useSearchParams } from 'react-router-dom';

const LessonFilterSidebar = (props) => {
  const {
    subjectsData: data,
    studentData,
    identity,
    archivedToggleFunction,
    archivedChecked,
  } = props;
  const {
    isLoading: listLoading,
    error: listError,
    filterValues,
  } = useListContext();
  // const { data, isLoading, error, } = useGetList('subjects', {
  //   pagination: { page: 1, perPage: 20 },
  //   sort: { field: 'subject', order: 'ASC' },
  // });

  console.log(`List Context filtervalues: ${JSON.stringify(filterValues)}`);
  const filterList = filterValues.grade ? [filterValues.grade] : null;
  console.log(`filterList: ${JSON.stringify(filterList)}`);
  const gradesList = filterList
    ? filterList
    : studentData
        .map((student) => student.classes.grade)
        .filter(function (el, idx, self) {
          return idx === self.indexOf(el);
        });
  console.log(`gradesList: ${JSON.stringify(gradesList)}`);

  return (
    <Card sx={{ order: -1, mr: 2, mt: 8, width: 380 }}>
      <CardContent>
        {['admin', 'teacher'].includes(identity.role) && (
          <ArchivedFilter
            checked={archivedChecked}
            onChange={archivedToggleFunction}
          />
        )}
        {/* <SavedQueriesList /> */}
        {/* <FilterLiveSearch source ="title"/> */}
        <FilterList label="Subjects" icon={<CategoryIcon />}>
          {data.map((record) => {
            return (
              // identity.role === 'admin' ||
              (record.grades.some((r) => gradesList.includes(r)) && (
                <FilterListItem
                  sx={{ borderLeft: `10px solid ${record.color}`, margin: 1 }}
                  label={record.subject}
                  value={{ subject_id: record.id }}
                  // isSelected={isSelected}
                  //toggleFilter={toggleFilter}
                  key={record.id}
                />
              )) ||
              ''
            );
          })}
        </FilterList>
      </CardContent>
    </Card>
  );
};

const ArchivedFilter = (props) => {
  const { checked, onChange } = props;
  return (
    <FormControlLabel
      style={{
        margin: '1px',
        color: 'black',
        backgroundColor: 'lightgrey',
        borderBottom: '1px solid grey',
        width: '72%',
      }}
      control={<Switch name="archived" checked={checked} onChange={onChange} />}
      label="Archived"
    />
  );
};

const LessonFilters = (props) => {
  const { subjectsData, studentData, identity } = props;
  const [searchParams] = useSearchParams();
  const selectedSubject = JSON.parse(searchParams.get('filter'))?.subject_id;
  console.log(`selectedSubject: ${selectedSubject}`);
  const subjectsMap = {};
  for (const subject of subjectsData) {
    subjectsMap[subject.id] = subject.grades;
  }
  console.log(`subjectsMap: ${JSON.stringify(subjectsMap)}`);
  if (selectedSubject)
    console.log(
      `selectedMap[selectSubject]: ${JSON.stringify(
        subjectsMap[selectedSubject]
      )}`
    );
  const learners = studentData.map(
    (student) =>
      (!selectedSubject ||
        subjectsMap[selectedSubject].includes(student.classes.grade)) && {
        id: student.classes.grade,
        name: `${student.first_name} ${student.last_name} - ${student.classes.class_code}`,
      }
  );
  const gradesList = studentData
    .map((student) => student.classes.grade)
    .filter(function (el, idx, self) {
      return idx === self.indexOf(el);
    });
  const grades = gradesList.sort().map((grade) => ({
    id: grade,
    name: `Grade ${grade}`,
  }));

  // console.log(`LessonFilter -> studentData: ${JSON.stringify(studentData[0])}`);
  return (
    <Filter {...props}>
      <SearchInput placeholder="Search title" source="title@ilike" alwaysOn />
      {identity.role === 'parent' && (
        <SelectInput
          label="Children"
          source="grade"
          // emptyText={`All`}
          validate={required()}
          choices={learners}
          alwaysOn
        />
      )}
      {['admin', 'teacher'].includes(identity.role) && (
        <SelectInput
          label="Grade"
          source="grade"
          emptyText={`All`}
          choices={grades}
          alwaysOn
        />
      )}
    </Filter>
  );
};

const LessonItemButton = () => {
  const record = useRecordContext();
  return (
    <Button
      // component={Link}
      // to={`/lessons/${record.id}/lessonItems`}
      // href={`/#/lessons/${record.id}/lessonItems`}
      href={`/lessons/${record.id}/lessonItems`}
      startIcon={<PermMediaIcon />}
    >
      Items
    </Button>
  );
};

function LessonsList(props) {
  const {
    data: subjectsData,
    isLoading: isSubjectsLoading,
    error: subjectsError,
  } = useGetList('subjects', {
    pagination: { page: 1, perPage: 20 },
    sort: { field: 'subject', order: 'ASC' },
  });
  const [studentData, setStudentData] = useState(false);
  const {
    data: identity,
    isLoading: isIdentityLoading,
    error: identityError,
  } = useGetIdentity();
  const [archived, setArchived] = useState(
    localStorage.getItem('lessonsArchived') || false
  );
  const toggleArchived = () => {
    console.log(`Toggling archived to ${!archived}`);
    setArchived(!archived);
    localStorage.setItem('lessonsArchived', !archived);
  };

  useEffect(() => {
    if (identity !== null) {
      getStudents(identity);
    }
    // setArchived(true); // TODO: This has to be removed before deploying.
  }, [identity]);

  const getStudents = async (identity) => {
    let options = {};
    if (identity) {
      switch (identity.role) {
        case 'parent':
          options = {
            household_hash: `${identity.household_hash}`,
          };
          break;
        case 'teacher':
          // Check if identity.class_id has a value, otherwise use grade: identity.grade
          if (identity.class_id !== null) {
            options = {
              class_id: identity.class_id,
            };
          } else {
            options = {
              grade: identity.grade,
            };
          }
          break;
        default:
          break;
      }
      const { data, error } = await supabaseClient
        .from('students')
        .select(
          `
          id,
          first_name,
          last_name,
          class_id,
          household_hash,
          classes (
            id,
            class_code,
            grade
          )
        `
        )
        .match(options);
      if (error)
        throw new Error(`Error fetching the students: ${error.message}`);
      console.log(`studentData: ${JSON.stringify(data)}`);
      setStudentData(data);
    }
  };

  if (isIdentityLoading || isSubjectsLoading) return <>Loading....</>;
  if (identityError || subjectsError) return <>Error...</>;

  return (
    studentData && (
      <List
        {...props}
        aside={
          <LessonFilterSidebar
            subjectsData={subjectsData}
            studentData={studentData}
            identity={identity}
            archivedChecked={archived}
            archivedToggleFunction={toggleArchived}
          />
        }
        filters={
          <LessonFilters
            subjectsData={subjectsData}
            studentData={studentData}
            identity={identity}
          />
        }
        filter={{ archived: archived }}
        filterDefaultValues={
          identity.role === 'parent'
            ? { grade: studentData[0]?.classes.grade }
            : {}
        }
        title="List of lessons"
        sx={{
          '& .RaDatagrid-table tr td:last-child': {
            maxWidth: '40%',
            width: '20%',
            // border: '1px solid red',
          },
        }}
      >
        <Pagination />
        <Datagrid
          rowStyle={ToggleRowStyle}
          bulkActionButtons={false}
          key={(record) => `${record.id}`}
          rowClick={['parent'].includes(identity.role) ? 'show' : false}
        >
          <RichTextField source="title" />
          <ReferenceField
            source="term_id"
            reference="terms"
            emptyText="TERM NOT SELECTED!"
          >
            <TextField source="name" />
          </ReferenceField>
          <RichTextField source="term_week" label="Week" />
          {['admin', 'teacher'].includes(identity.role) ? (
            // <TextField source="subject" />
            <ReferenceField
              source="subject_id"
              reference="subjects"
              emptyText="SUBJECT NOT LINKED!"
            />
          ) : null}
          {['admin', 'teacher'].includes(identity.role) ? (
            <TextField source="grade" />
          ) : null}
          {/* <DateField source="published" /> */}
          {['admin', 'teacher'].includes(identity.role) ? (
            <>
              <EditButton />
              <DeleteButton mutationMode="pessimistic" />
            </>
          ) : null}
        </Datagrid>
      </List>
    )
  );
}

export default LessonsList;

