import { Save } from '@mui/icons-material';
import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  BooleanInput,
  SaveButton,
  Toolbar,
} from 'react-admin';

const TermEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const TermEdit = (props) => {
  return (
    <Edit {...props} title="Edit student">
      <SimpleForm toolbar={<TermEditToolbar />}>
        <TextInput disabled source="id" style={{ display: 'none' }} />
        <TextInput source="name" />
        <DateInput source="from_date" />
        <DateInput source="to_date" />
        <BooleanInput label="Is active?" source="active" />
      </SimpleForm>
    </Edit>
  );
};

export default TermEdit;

