import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

var DEBUG = process.env.NODE_ENV === 'development';
if (!DEBUG) {
  if (!window.console) window.console = {};
  var methods = ['log', 'debug', 'warn', 'info'];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
{
  /*
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
