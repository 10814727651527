import React from 'react';
import {
  // Loading,
  List,
  Filter,
  Datagrid,
  TextField,
  BooleanField,
  SearchInput,
  EditButton,
  Pagination,
  DateField,

  // useRecordContext,
} from 'react-admin';
import ToggleRowStyle from './Common';

// const ParentFilter = (props) => (
//     <Filter {...props}>
//         {/* <SearchInput placeholder='Parent ID' source='id' resettable alwaysOn /> */}
//         <SearchInput placeholder='Parent last name' source='last_name' resettable alwaysOn />
//         <SearchInput placeholder='Parent first name' source='first_name' resettable alwaysOn />
//     </Filter>
// );

const TermList = (props) => {
  return (
    <List
      {...props}
      // filters={<ParentFilter />}
      title="List of parents"
    >
      <Pagination />
      <Datagrid
        rowClick="expand"
        rowStyle={ToggleRowStyle}
        bulkActionButtons={false}
      >
        <TextField source="name" />
        <DateField source="from_date" />
        <DateField source="to_date" />
        <BooleanField source="active" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default TermList;

