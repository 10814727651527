import { withLifecycleCallbacks } from 'react-admin';
import { supabaseDataProvider } from 'ra-supabase';
import { supabaseClient, instanceUrl, apiKey } from './components/supabase';
import getWeeksPerTerm from './components/TermFunctions';

const baseDataProvider = supabaseDataProvider({
  instanceUrl: instanceUrl,
  apiKey: apiKey,
  supabaseClient,
});

const findMediaType = (ext) => {
  const mediaTypes = {
    D: ['docx', 'pdf', 'txt'],
    P: ['png', 'jpg', 'jpeg', 'bmp'],
    V: ['mov', 'mp4', 'avi', 'mkv'],
  };

  for (const [key, value] of Object.entries(mediaTypes)) {
    if (value.includes(ext)) {
      return key;
    }
  }
  return null;
};

export const dataProvider = withLifecycleCallbacks(
  {
    ...baseDataProvider,
    // TODO: Use this approach BUT inject something to say it was already created
    create: async (resource, params) => {
      console.log(`create override: ${JSON.stringify(params)}`);
      if (resource === 'lessons' && params?.data?.createdLesson) {
        console.log(`Not creating a new lesson, already created.`);
        return params;
      } else {
        console.log(`Creating a new lesson, NOT already created.`);
        baseDataProvider.create(resource, params);
      }
    },
  },
  [
    {
      resource: 'lessons',
      beforeGetList: async (params, dataProvider) => {
        // Retrieve user identity from localStorage
        const identityString = localStorage.getItem('identity');
        let userRole = null;

        if (identityString) {
          try {
            const identity = JSON.parse(identityString);
            userRole = identity.role;
          } catch (error) {
            console.error('Error parsing identity from localStorage');
          }
        }

        // Only apply the filter if the user is NOT an admin
        if (userRole !== 'admin') {
          const { data: terms } = await dataProvider.getList('terms', {
            filter: { active: true }, // Only fetch active terms
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
          });

          // Extract active IDs
          const activeTermIds = terms.map((term) => term.id);

          // Apply filter to include only lessons with an active term_id
          return {
            ...params,
            filter: {
              ...params.filter,
              term_id: activeTermIds, // Ensuring lessons are from active terms
            },
          };
        }

        // If the user is an admin user
        return params;
      },
      beforeDelete: async (params, dataProvider) => {
        console.log(`+++ FETCHING RELATED LESSON ITEMS +++`);
        const { data: lesson_items, error } = await dataProvider.getList(
          'lesson_items',
          {
            filter: {
              lesson_id: params.id,
            },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'DESC' },
          }
        );
        if (error)
          console.log(`getManyReference error: ${JSON.stringify(error)}`);
        if (lesson_items.length > 0) {
          console.log(`+++ DELETING +++`);
          console.log(JSON.stringify(lesson_items));
          await dataProvider.deleteMany('lesson_items', {
            ids: lesson_items.map((lesson_item) => lesson_item.id),
          });
        }
        return params;
      },
      beforeSave: async (params, dataProvider) => {
        console.log(`+++ LESSONS SAVE +++`);
        console.log(JSON.stringify(params));
        const attachments = params.attachments ?? null;
        delete params.attachments;
        params.toggle = !params.toggle;
        const { data: terms } = await supabaseClient.from('terms').select('*');
        const weeksPerTerm = getWeeksPerTerm(terms);
        const weeksList = weeksPerTerm[params.term_id];
        const minuend = params.week;
        const subtrahend = weeksList[0] - 1;
        const difference = minuend - subtrahend;
        params.term_week = difference;
        if (!params.id || params.id === null) {
          console.log(`+++ CREATING NEW LESSON +++`);
          console.log(JSON.stringify(params));
          const { data: newLesson } = await supabaseClient
            .from('lessons')
            .insert([params])
            .select();
          console.log(`+++ newLesson contents +++`);
          console.log(JSON.stringify(newLesson));
          params.createdLesson = true;
          params.id = newLesson[0].id;
          console.log(`+++ PARAMS AFTER NEW LESSON CREATE +++`);
          console.log(JSON.stringify(params));
          // TODO: Try to use supabaseClient instead.
        }
        const lesson_items = [];
        if (attachments !== null) {
          for (const attachment of attachments) {
            console.log(`--- UPLOADING ---`);
            console.log(JSON.stringify(attachment));
            const unique_attachment_title = `${Date.now()}$:$${
              attachment.title
            }`;
            const { error } = await supabaseClient.storage
              .from('media')
              .upload(unique_attachment_title, attachment.rawFile, {
                contentType: 'auto',
                upsert: true,
              });

            if (error) throw error;
            const regex = new RegExp('[^.]+$');
            const extension = attachment.title.match(regex);
            const mediaType = findMediaType(extension.toLocaleString());
            // Setup Lesson Items here
            lesson_items.push({
              title: attachment.title,
              file: unique_attachment_title,
              type: mediaType,
              lesson_id: params.id,
            });
          }
          // Insert Lesson Items here
          console.log(`... SAVING LESSON ITEMS ...`);
          console.log(JSON.stringify(lesson_items));
          if (lesson_items.length) {
            const { error: dataError } = await supabaseClient
              .from('lesson_items')
              .insert(lesson_items);
            //.select()
            // .maybeSingle()
            if (dataError) throw dataError;
          }
          return params;
        }
        // Return the Lesson Data here.
        console.log(`======= beforeSave return data =======`);
        console.log(JSON.stringify(params));
        return params;
      },
    },
    {
      resource: 'lesson_items',
      beforeSave: async (params, dataProvider) => {
        // I doubt that the dataProvider here is needed.
        console.log(
          `beforeSave method in dataProvider for lesson_items -> params: ${JSON.stringify(
            params
          )}`
        );
        if (params.media !== null) {
          const { error } = await supabaseClient.storage
            .from('media')
            .upload(params.media.title, params.media.rawFile, {
              contentType: 'auto', // or specify the mime type if you know it
              upsert: true,
            });

          if (error) {
            throw error;
          }
          const regex = new RegExp('[^.]+$');
          const extension = params.media.title.match(regex);
          const mediaType = findMediaType(extension.toLocaleString());
          console.log(`The extracted file extension: ${extension}`);
          console.log(`The returned mediaType: ${mediaType}`);
          return {
            id: params.id,
            description: params.description,
            file: params.media.title,
            lesson_id: params.lesson_id,
            title: params.title,
            type: mediaType,
          };
        }
        return {
          id: params.id,
          description: params.description,
          file: '',
          lesson_id: params.lesson_id,
          title: params.title,
          type: '',
        };
      },
    },
    {
      resource: 'resources',
      beforeSave: async (params, dataProvider) => {
        // I doubt that the dataProvider here is needed.
        console.log(
          `beforeSave method in dataProvider for resources -> params: ${JSON.stringify(
            params
          )}`
        );
        if (params.media !== null) {
          const { error } = await supabaseClient.storage
            .from('media')
            .upload(params.media.title, params.media.rawFile, {
              contentType: 'auto', // or specify the mime type if you know it
              upsert: true,
            });

          if (error) {
            throw error;
          }
          const regex = new RegExp('[^.]+$');
          const extension = params.media.title.match(regex);
          const mediaType = findMediaType(extension.toLocaleString());
          console.log(`The extracted file extension: ${extension}`);
          console.log(`The returned mediaType: ${mediaType}`);
          return {
            id: params.id,
            resource_type_id: params.resource_type_id,
            description: params.description,
            file: params.media.title,
            title: params.title,
            type: mediaType,
          };
        }
        return {
          id: params.id,
          resource_type_id: params.resource_type_id,
          description: params.description,
          file: '',
          title: params.title,
          type: '',
        };
      },
    },
    // {
    //     resource: "lessons",
    //     afterGetList: (result, dataProvider, resource) => {
    //         const { data, error } = supabaseClient
    //             .from("lessons")
    //         console.log(`Before filtering ${JSON.stringify(result)}`);
    //         const _data = result.data.filter((item) => item.published <= Date());
    //         const filtered_data = {
    //             data: _data,
    //             total: result.total
    //         };
    //         console.log(`After filtering ${JSON.stringify(filtered_data)}`);
    //         return filtered_data;
    //     }
    // }
  ]
);
